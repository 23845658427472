<template>
  <SendInvoiceDialog
    v-if="isModalOpen"
    v-model="isModalOpen"
    :invoice-id="selectedRow.id"
    @close="closeInvoiceModal"
    @cancel="closeInvoiceModal"
    @submit="updateInvoiceStatus"
  />
  <BaseDataTable
    ref="table"
    url="/restify/invoices"
    :url-params="urlParams"
    :columns="columns"
    :row-height="60"
    :extra-actions="rowActions"
    actions="view,edit,delete"
    entity="invoices"
    class="flex-1"
  >
    <template #attributes.due_date="{ row }">
      <router-link
        class="flex space-x-2 no-underline text-gray-900"
        :to="`/invoices/${row.id}/details`"
      >
        <div>
          <InvoiceStatusComponent :status="row.attributes.status" />
        </div>
        <div
          v-if="!allInvoices"
          :to="`/invoices/${row.id}/details`" class="no-underline text-gray-900"
        >
          <span
            :class="{
              'text-red-500 font-medium': getDueDays(row.attributes.due_date) < 0,
            }"
          >
            {{ getDueDaysText(row.attributes.due_date) }}
          </span>
        </div>
      </router-link>
    </template>
    <template #attributes.client_id="{ row }">
      <div class="flex flex-col">
        <span class="font-bold leading-5">
          {{ getClientName(row.attributes.client_id) }}
        </span>
        <span class="text-gray-500 text-sm leading-5">
          {{ row.attributes?.title }}
        </span>
      </div>
    </template>
  </BaseDataTable>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { ColDef, ValueFormatterParams } from '@ag-grid-community/core'
import { DocumentDuplicateIcon, EnvelopeIcon } from "@heroicons/vue/24/outline"
import { formatPrice } from '@/plugins/formatPrice'
import { formatDate } from '@/modules/common/utils/dateUtils'
import { useClientStore } from '@/modules/clients/store/clientStore'
import InvoiceStatusComponent from '@/modules/invoices/components/InvoiceStatus.vue'
import { getDueDays, getDueDaysText } from '@/modules/invoices/utils/invoiceUtils'
import { FilterTypes } from "@/components/table/filters/filterTypes"
import { RowAction } from "@/components/table/tableTypes"
import i18n from "@/i18n"
import { can } from "@/plugins/permissionPlugin"
import SendInvoiceDialog from "@/modules/invoices/components/SendInvoiceDialog.vue"
import { InvoiceStatus } from "@/modules/invoices/types/invoicEnums"
import { Invoice } from "@/modules/invoices/types/invoiceTypes"

import Data = API.Data
import { useInvoiceStore } from "@/modules/invoices/store/invoiceStore"
const props = defineProps({
  unpaid: {
    type: Boolean,
    default: false,
  },
  client_id: {
    type: String,
  },
  allInvoices: {
    type: Boolean,
    default: false,
  },
})

const isModalOpen = ref(false)
const selectedRow = ref()

function closeInvoiceModal() {
  isModalOpen.value = false
}

const urlParams = computed(() => {
  return {
    paid_at: props.unpaid ? 'null' : undefined,
    client_id: props.client_id,
  }
})
const { t } = useI18n()
const clientStore = useClientStore()
const columns = computed<ColDef[]>(() => {
  return [
    {
      headerName: t('Status'),
      field: 'attributes.due_date',
      minWidth: props.allInvoices ? 90 : 280,
      filter: FilterTypes.InvoiceStatus,
    },
    {
      headerName: t('Issue date'),
      field: 'attributes.issue_date',
      minWidth: 150,
      maxWidth: 180,
      valueFormatter: params => formatDate(params.value),
      filter: FilterTypes.IssueDate,
    },
    {
      headerName: t('Paid at'),
      field: 'attributes.paid_at',
      minWidth: 150,
      maxWidth: 180,
      valueFormatter: params => formatDate(params.value),
      hide: !props.allInvoices,
    },
    {
      headerName: t('ID'),
      field: 'attributes.invoice_number',
      minWidth: 90,
      maxWidth: 100,
    },
    {
      headerName: t('Client'),
      field: 'attributes.client_id',
      minWidth: 200,
      maxWidth: 300,
      filter: !props.client_id ? FilterTypes.Client : undefined,
    },
    {
      headerName: t('Amount'),
      field: 'attributes.total',
      minWidth: 140,
      type: 'rightAligned',
      cellClass: 'font-bold text-right',
      valueFormatter: (params) => {
        return formatClientPrice(params, params.value)
      },
      hide: !props.allInvoices,
    },
    {
      headerName: t('Paid'),
      field: 'attributes.total',
      minWidth: 140,
      type: 'rightAligned',
      cellClass: 'font-bold text-right',
      valueFormatter: (params) => {
        let amount = 0
        if (params.data?.attributes?.paid_at) {
          amount = params.value
        }
        return formatClientPrice(params, amount)
      },
      hide: !props.allInvoices,
    },
    {
      headerName: t('Balance'),
      field: 'attributes.total',
      minWidth: 140,
      type: 'rightAligned',
      cellClass: 'font-bold text-right',
      valueFormatter: (params) => {
        let amount = params.value
        if (params.data?.attributes?.paid_at) {
          amount = 0
        }
        return formatClientPrice(params, amount)
      },
    },
  ]
})
const invoiceStore = useInvoiceStore()
const table = ref()
async function refreshTable() {
  await table.value?.refresh()
}

const rowActions = computed<RowAction[]>(() => {
  return [
    {
      label: i18n.t('Duplicate'),
      icon: DocumentDuplicateIcon,
      action: async (row: Data<Invoice>) => {
        await invoiceStore.duplicateInvoice(row.id as string)
        await refreshTable()
      },
      show: () => {
        return can('manageInvoices')
      },
    },
    {
      label: i18n.t('Send Invoice'),
      icon: EnvelopeIcon,
      action: async (row: Data<Invoice>) => {
        isModalOpen.value = true
        selectedRow.value = row
      },
      show: (row: Data<Invoice>) => {
        if (!can('manageInvoices')) {
          return
        }
        return row.attributes.status !== InvoiceStatus.Paid
      },
    },
  ]
})
function formatClientPrice(params: ValueFormatterParams, amount: number) {
  const { currency } = params.data.attributes
  return formatPrice(amount, {
    currency,
  })
}

function getClient(client_id: string) {
  return clientStore.getClientById(client_id)
}

function getClientName(client_id: string) {
  const client = getClient(client_id)
  return client?.attributes?.company_name
}
function updateInvoiceStatus() {
  selectedRow.value.attributes.status = InvoiceStatus.Sent
  closeInvoiceModal()
}
</script>
