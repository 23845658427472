<template>
  <div>
    <div class="flex flex-col sm:flex-row space-x-2">
      <div class="space-y-2 mb-4 flex flex-1 flex-col">
        <BaseContentCard :title="$t('Project details')">
          <ContentCardRow :title="$t('Client')">
            <router-link :to="`/clients/${projectClient.id}/details`" class="text-gray-900 no-underline">
              {{ projectClient?.attributes.company_name }}
            </router-link>
          </ContentCardRow>
          <ContentCardRow :title="$t('Name')">
            {{ currentProject.name }}
          </ContentCardRow>
          <ContentCardRow :title="$t('Description')" :truncate="false">
            <div class="prose prose-sm break-words max-w-3xl" v-html="currentProject.description" />
          </ContentCardRow>
        </BaseContentCard>
      </div>
    </div>
    <BaseContentCard v-if="$can('manageInvoices')">
      <ReportHeader :show-bread-crumbs="false"/>
      <TeamReport
        :project-id="projectId"
        :show-all-project-employees="false"
      />
    </BaseContentCard>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import BaseContentCard from '@/components/common/BaseContentCard.vue'
import ContentCardRow from '@/components/common/ContentCardRow.vue'
import { useAuthStore } from '@/modules/auth/store/authStore'
import { useProjectStore } from '@/modules/projects/store/projectStore'
import TeamReport from '@/modules/reports/pages/reports/projects/[id]/team.vue'
import Client = App.Domains.Clients.Models.Client
import Data = API.Data
import ReportHeader from "@/modules/reports/components/ReportHeader.vue"
import { useReportsStore } from "@/modules/reports/store/reportsStore";
const { t } = useI18n()

const projectStore = useProjectStore()
const currentProject = computed<any>(() => {
  return projectStore.currentProject
})

const projectClient = computed<Data<Client>>(() => {
  return currentProject.value?.relationships?.client
})

const route = useRoute()
const projectId = computed(() => {
  return route.params.id as string
})

const reportStore = useReportsStore()
reportStore.resetIdFilters()
</script>
