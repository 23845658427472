<template>
  <component
    v-if="row?.id"
    :is="(isArchived || !showLink) ? 'div' : 'router-link'"
    :to="to || `/employees/${row.id}/details`"
    class="no-underline text-base-content"
  >
    <EmployeeAvatar
      :data="row"
      :is-for-public-report="isForPublicReport"
      :show-position="params.showPosition"
    />
  </component>
  <div v-else>
    - -
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import EmployeeAvatar from '@/modules/employees/components/EmployeeAvatar.vue'
import { useEmployeeStore } from '@/modules/employees/store/employeeStore'
import { EmployeeStatus } from '@/modules/employees/utils/employeeUtils'

const props = defineProps({
  params: {
    type: Object,
    default: () => ({}),
  },
  to: {
    type: [String, Object],
  },
  showLink: {
    type: Boolean,
    default: true,
  },
  isForPublicReport: {
    type: Boolean,
    default: false,
  },
})

const employeeStore = useEmployeeStore()
const { allEmployees } = storeToRefs(employeeStore)
const row = computed(() => {
  let employeeRelationship = props.params.data?.relationships?.employee
  if (!employeeRelationship) {
    employeeRelationship = allEmployees.value.find(employee => employee.id?.toString() === props.params.value?.toString())
  }
  if (employeeRelationship === undefined) {
    return props.params?.data
  }
  return employeeRelationship || {}
})

const isArchived = computed(() => {
  return row.value.attributes?.status === EmployeeStatus.Archived
})
</script>
