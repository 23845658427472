<template>
  <div>
    <ElPopover
      trigger="click"
      popper-class="filter-popover"
      placement="bottom-start"
      @after-enter="focusOnInput"
    >
      <template #reference>
        <FilterButton class="group">
          {{ filterLabel }}
          <div v-if="selectedTags.length > 0" class="shrink-0 bg-gray-200 w-[1px] mx-2 h-4" />
          <div class="hidden space-x-1 lg:flex">
            <FilterTag v-for="tag in selectedTags" :key="tag">
              {{ tag }}
            </FilterTag>
          </div>
          <FilterClearButton
            v-if="selectedTags?.length > 0"
            @click="resetFilters"
          />
        </FilterButton>
      </template>

      <Combobox
        :model-value="selected"
        :multiple="multiple"
        @update:model-value="updateSelections"
      >
        <div ref="inputWrapper" class="border-b border-gray-200">
          <ComboboxInput
            class="input input-sm w-full focus:outline-none focus:ring-0 filter-input"
            :default-value="() => query"
            @change="query = $event.target.value"
          />
        </div>

        <ComboboxOptions
          :static="true"
          class="max-h-60 w-full overflow-auto py-1 text-base ring-opacity-5 focus:outline-none sm:text-sm"
        >
          <div
            v-if="filteredData.length === 0 && query !== ''"
            class="relative cursor-default select-none py-2 px-4 text-gray-700"
          >
            {{ $t('Nothing found.') }}
          </div>

          <ComboboxOption
            v-for="item in filteredData"
            :key="get(item, valueKey)"
            v-slot="{ selected, active }"
            as="template"
            :value="item"
          >
            <li
              class="relative cursor-default select-none py-2 pl-10 pr-4"
              :class="{
                'bg-primary text-white': active,
                'text-base-content': !active,
                [itemClasses]: itemClasses,
              }"
            >
              <span
                class="block truncate"
                :class="{ 'font-medium': selected, 'font-normal': !selected }"
              >
                <component
                  :is="itemComponent"
                  v-if="itemComponent"
                  :params="{
                    value: get(item, valueKey),
                  }"
                  :value="get(item, valueKey)"
                />
                <template v-else>
                  {{ get(item, labelKey) }}
                </template>
              </span>
              <span
                v-if="selected"
                class="absolute inset-y-0 left-0 flex items-center pl-3"
                :class="{ 'text-white': active, 'text-primary': !active }"
              >
                <CheckIcon class="h-5 w-5" aria-hidden="true" />
              </span>
            </li>
          </ComboboxOption>
          <ComboboxOption
            v-if="hasValues"
            key="clear"
            v-slot="{ selected, active }"
            :value="ClearValue"
            class="border-t border-gray-200"
          >
            <button
              class="w-full py-2 hover:bg-gray-100 text-sm text-base-content hover:text-base-content"
              :class="{
                'bg-primary text-white': active,
              }"
            >
              {{ $t('Clear filters') }}
            </button>
          </ComboboxOption>
        </ComboboxOptions>
      </Combobox>
    </ElPopover>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { Combobox, ComboboxInput, ComboboxOption, ComboboxOptions } from '@headlessui/vue'
import { CheckIcon } from '@heroicons/vue/20/solid'
import { useI18n } from "vue-i18n"
import { get } from "lodash-es"
import FilterTag from "@/components/table/filters/base/FilterTag.vue"
import FilterClearButton from "@/components/table/filters/base/FilterClearButton.vue"

const props = defineProps({
  valueKey: {
    type: String,
    default: 'value',
  },
  labelKey: {
    type: String,
    default: 'label',
  },
  maxValuesToDisplay: {
    type: Number,
    default: 2,
  },
  filterLabel: {
    type: [String, Object],
    default: 'Status',
  },
  multiple: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: [String, Array],
    default: null,
  },
  data: {
    type: Array,
    default: () => [],
  },
  itemComponent: {
    type: Object,
  },
  itemClasses: {
    type: String,
  }
})

const emit = defineEmits(['update:modelValue'])

const inputWrapper = ref()

const ClearValue = 'clear'
const selected = ref<any>(props.multiple ? [] : null)

if (props.modelValue) {
  initModelValue()
}

const hasValues = computed(() => {
  return Array.isArray(selected.value) ? selected.value.length > 0 : selected.value !== null
})

function resetSelection() {
  selected.value = props.multiple ? [] : null
}

function resetFilters() {
  resetSelection()
  emit('update:modelValue', getFilterUrlValue(selected.value))
}

function initModelValue() {
  if (props.multiple) {
    selected.value = props.data.filter(findItemValueFunction)
  } else {
    selected.value = props.data.find(findItemValueFunction)
  }
}

function findItemValueFunction(item: any) {
  const itemValue = get(item, props.valueKey)
  if (Array.isArray(props.modelValue)) {
    return props.modelValue.includes(itemValue)
  }
  return itemValue === props.modelValue
}

watch(() => props.modelValue, initModelValue)
function updateSelections(value: any) {
  const isReset = (Array.isArray(value) && value.includes(ClearValue)) || value === ClearValue
  if (isReset) {
    resetSelection()
  } else {
    selected.value = value
  }
  emit('update:modelValue', getFilterUrlValue(selected.value))
}

function getFilterUrlValue(value: string | string[]) {
  if (Array.isArray(value)) {
    return value.map(item => get(item, props.valueKey))
  } else if (typeof value === 'object') {
    return get(value, props.valueKey)
  } else if (value === ClearValue) {
    return null
  }
  return value
}

const query = ref('')

const filteredData = computed(() =>
  query.value === ''
    ? props.data
    : props.data.filter(item =>
      get(item, props.labelKey)
        .toLowerCase()
        .replace(/\s+/g, '')
        .includes(query.value.toLowerCase().replace(/\s+/g, '')),
    ),
)

const { t } = useI18n()

const selectedTags = computed(() => {
  if (Array.isArray(selected.value)) {
    const selectedValues = selected.value.map((item: any) => get(item, props.labelKey))
    const selectCount = `${selectedValues.length} ${t('selected')}`
    return selectedValues.length > props.maxValuesToDisplay ? [selectCount] : selectedValues
  }
  const value = get(selected.value, props.labelKey)
  return value ? [value] : []
})

function focusOnInput() {
  const input = inputWrapper.value.querySelector('input')
  if (!input) {
    return
  }
  input.focus()
  input.placeholder = props.filterLabel
}
</script>

<style lang="scss">
.el-popover.filter-popover {
  --input-height: 36px;

  @apply p-0 min-w-[200px];
  .filter-input {
    height: var(--input-height);
  }
}
</style>
